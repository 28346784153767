.tp-cc-slider {
  $self: &;
  width: 100%;

  .swiper-container {
    position: relative;
    z-index: 4;
    //max-width: 800px;
    background: $white;
    color: $primary-color;
    box-shadow: 0px 14px 80px rgba(0, 0, 0, 0.2);
    padding: 25px;
    //border-radius: 25px;
    //height: 400px;
    transition: all 0.3s;
    overflow: visible;
    margin: 100px 0 0;

    @include respond-above(sm) {
      margin: 50px 125px 0 125px;
    }

    @include respond-above(xl) {
      margin: auto;
    }

    .swiper-slide {
      display: flex;
      align-items: center;
      background: $white;

      @include respond-below(lg) {
        flex-direction: column;
      }

      .image-wrapper {
        width: 430px;
        max-width: 100%;
        transform: translateY(-50%);

        @include respond-between(sm, lg) {
          transform: translateY(-30%);
        }

        @include respond-above(lg) {
          transform: translateX(0);
          transform: translateX(-135px);
        }

        .image {
          position: relative;
          width: 100%;
          padding-top: 62%;
          box-shadow: 2px 7px 15px 1px rgba(0, 0, 0, 0.2);
          border-radius: 10px;
          transition: all 0.3s;
          opacity: 0;
          overflow: hidden;

          img {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            object-fit: cover;
            display: block;
          }
        }
      }

      .content {
        transform: translateY(-40%);
        width: 100%;

        @include respond-between(sm, lg) {
          transform: translateY(-20%);
        }

        @include respond-above(lg) {
          transform: translateX(0);
          transform: translateX(-100px);
        }

        > * {
          opacity: 0;
          transform: translateY(25px);
          transition: all 0.4s;
        }

        .date {
          font-size: 12px;
          color: $medium-gray-color;
        }

        .title {
          color: $secondary-color;
        }

        .desc {
          margin-bottom: 30px;
        }
      }

      &.swiper-slide-active {
        .image {
          opacity: 1;
          transition-delay: 0.3s;
        }
        .content {
          > * {
            opacity: 1;
            transform: none;

            @for $i from 0 to 15 {
              &:nth-child(#{$i + 1}) {
                transition-delay: $i * 0.1 + 0.3s;
              }
            }
          }
        }
      }
    }

    .swiper-container-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
      bottom: 10px;
      left: 0;
      width: 100%;
    }
    .swiper-pagination {
      position: absolute;
      z-index: 21;
      right: 20px;
      width: 11px;
      text-align: center;
      left: auto;
      top: 50%;
      bottom: auto;
      transform: translateY(-50%);

      @include respond-below(lg) {
        bottom: 20px;
        top: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: translateX(-50%);
        left: 50%;
        width: 100%;
      }

      &.swiper-pagination-bullets .swiper-pagination-bullet {
        margin: 8px 0;
        @include respond-below(lg) {
          margin: 0 5px;
        }
      }

      .swiper-pagination-bullet {
        width: 11px;
        height: 11px;
        display: block;
        border-radius: 10px;
        background: $medium-gray-color;
        opacity: 0.2;
        transition: all 0.3s;
        &-active {
          opacity: 1;
          background: $secondary-color;
          height: 30px;
          box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);

          @include respond-below(lg) {
            height: 11px;
            width: 30px;
          }
        }
      }
    }
  }
}
