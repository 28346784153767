.tp-under-hero {
  $self: &;
  position: relative;
  overflow: hidden;
  padding: 30px 0 60px;

  @include respond-above(md) {
    padding: 60px 0 60px;
  }

  > .container-fluid {
    display: flex;
    flex-direction: column;

    @include respond-above(lg) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .title-wrapper {
    position: relative;
    z-index: 3;
    width: 100%;
    padding-right: 0;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    @include respond-above(xl) {
      //width: $base-left-space;
      //margin-bottom: 0;
      //padding-right: 64px;
    }

    > .title {
      font-weight: 700;

      @include respond-above(xl) {
        // max-width: $base-unfold-title-max-width;
      }
    }

    > a {
      position: relative;
      display: inline-block;
      opacity: 0;
      transform: $base-vertical-animation-transform;
      transition: $base-vertical-animation-transition;
      vertical-align: middle;
      margin-left: 20px;
    }
  }

  .prices {
    width: 100%;
    position: relative;

    @include respond-above(lg) {
      width: 65%;
    }

    > .info,
    .selects,
    > .price-list {
      opacity: 0;
      transform: $base-vertical-animation-transform;
      transition: $base-vertical-animation-transition;
    }

    > .info {
      font-size: 12px;
      color: $medium-gray-color;
      margin-top: 10px;
    }

    .selects {
      display: flex;
      margin-bottom: 20px;

      > .tp-select {
        width: 300px;
        max-width: 100%;

        &:first-child {
          margin-right: 10px;
        }
      }
    }

    > .price-list {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      position: relative;

      @include respond-above(sm) {
        flex-wrap: nowrap;
      }

      > .price-list__loader {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(255, 255, 255, 0.75);
        z-index: 1;
        color: $secondary-color;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      > .price-list-item {
        position: relative;
        margin: 0 2% 4% 0;
        height: 150px;
        width: 48%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0 2% 0 0;

        @include respond-above(xs) {
          width: 31%;
          margin: 0 2% 3% 0;
        }

        @include respond-above(md) {
          flex-grow: 1;
          flex-basis: 0;
          width: auto;
          margin: 0 2% 2% 0;
        }

        &:after {
          content: "";
          position: absolute;
          right: 0;
          width: 1px;
          top: 5%;
          height: 90%;
          background-color: $medium-gray-color;
        }

        > .price-list-item-title {
          font-size: 18px;
          flex-grow: 1;
          font-weight: 700;
        }

        > .price-list-item-value {
          color: $secondary-color;
          font-weight: 700;
          font-size: 30px;
          padding: 10px 0;
        }

        > .price-list-item-unit {
          color: $medium-gray-color;
          font-size: 14px;
          font-weight: 700;
        }

        @include respond-below(xs) {
          &:nth-child(even) {
            &:after {
              display: none;
            }
          }
        }

        @include respond-between(xs, sm) {
          &:nth-child(3),
          &:nth-child(6) {
            &:after {
              display: none;
            }
          }
        }

        @include respond-above(sm) {
          &:last-child {
            &:after {
              display: none;
            }
          }
        }
      }
    }
  }

  .stations {
    width: 100%;
    margin-top: 60px;
    position: relative;

    @include respond-above(lg) {
      width: 30%;
      margin-top: 0;
      display: flex;
      align-items: center;
    }

    > .stations-card {
      background-color: transparent;
      width: 100%;
      height: 220px;
      perspective: 1000px;
      margin-top: 20px;
      cursor: pointer;

      opacity: 0;
      transform: $base-vertical-animation-transform;
      transition: $base-vertical-animation-transition;

      > .stations-card-inner {
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        transition: transform 0.8s;
        transform-style: preserve-3d;

        > .front,
        > .back {
          position: absolute;
          width: 100%;
          height: 100%;
          backface-visibility: hidden;
          box-shadow: 0 0.25vmin 0.25vmin rgba(black, 0.25);
        }

        > .front {
          background: linear-gradient(135deg, $secondary-color, #be0510);
          display: flex;
          align-items: center;
          border-radius: 1000px 50px 50px 1000px;
          padding: 40px;

          > .icon-wrapper {
            margin: 20px;
            padding: 15px;
            border-radius: 1000px;
            border: 10px solid $white;
            background: $secondary-color;
            box-shadow: 0px 5px 50px 0px rgba(0, 0, 0, 0.2),
              0px 0px 0px 7px rgba(0, 0, 0, 0.1);

            i {
              color: $white;
              font-size: 60px;
            }
          }

          > .text {
            font-size: 30px;
            font-weight: bold;
            color: $white;
          }
        }

        > .back {
          position: relative;
          background: linear-gradient(135deg, $medium-gray-color, $light-color);
          color: white;
          transform: rotateY(180deg);
          border-radius: 50px 1000px 1000px 50px;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          flex-direction: column;
          padding: 0 20% 0 15%;
          overflow: hidden;

          &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 0;

            background-image: url(#{$image-path}/blank-map.png);
            background-repeat: no-repeat;
            background-size: 120%;
            background-position: center;
            opacity: 0.7;
            filter: invert(1);
          }

          > .form {
            width: 80%;
            height: 100px;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;

            > .tp-select {
              width: 100%;
              margin-bottom: 20px;
            }

            > div {
              z-index: 1;
            }
          }
        }
      }

      &.flip {
        > .stations-card-inner {
          transform: rotateY(180deg);
        }
      }
    }
  }

  &.is-inview {
    .info,
    .selects,
    .price-list,
    .stations-card,
    .title-wrapper > a {
      transform: none;
      opacity: 1;
    }
  }
}
