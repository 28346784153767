.page-link {
  color: #4d515c;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-item.active .page-link {
  color: #fff;
  background-color: $secondary-color;
  border-color: $secondary-color;
}
