.tp-footer {
  $self: &;

  a {
    text-decoration: none;
  }

  .top-box {
    padding: 40px 0 20px;
    margin: 0;
    background-color: $light-color;

    @include respond-above(md) {
      padding: 60px 0 40px;
    }

    .logo {
      display: block;
      margin: 0 0 40px;
      width: 80px;
      height: 17px;
      order: 2;

      img {
        mix-blend-mode: multiply;
      }

      @include respond-above(md) {
        width: 160px;
        height: 33px;
        order: 0;
      }
    }

    .footer-grid {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
    }

    .nav-wrapper {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      width: 100%;

      @include respond-above(md) {
        width: calc(100% - 430px);
      }

      .nav-box {
        width: 96%;
        margin: 0 2% 2% 0;

        @include respond-above(sm) {
          width: 46%;
        }

        @include respond-above(xl) {
          width: 29%;
        }
      }
    }

    .nav {
      font-size: 16px;
      .nav-item {
        .nav-link {
          padding-left: 0;
          padding-bottom: 0;
          display: inline;
        }
      }
    }

    .footer-contacts {
      width: 100%;
      padding-top: 25px;
      text-align: center;
      border-top: 1px solid #e3e4e8;

      @include respond-above(md) {
        width: 430px;
        padding-top: 0;
        text-align: left;
        border-top: none;
      }

      p {
        font-size: 16px;
        font-weight: 700;
        margin: 1px 0 4px;
      }

      a[href^="tel"] {
        font-weight: 500;
        font-size: 50px;
        line-height: 1em;
        letter-spacing: -0.783333px;
        color: $secondary-color;
        text-decoration: none;
      }

      a[href^="mailto"] {
        position: relative;
        display: block;
        margin-top: 10px;
        padding-top: 10px;
        font-weight: 500;
        font-size: 20px;
        color: $secondary-color;
        text-decoration: none;

        @include respond-above(md) {
          &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 30px;
            height: 1px;
            background-color: #e3e4e8;
          }
        }
      }

      .social-links {
        border-top: 1px solid #e3e4e8;
        margin: 15px 0 0;
        padding-top: 10px;

        a {
          margin: 0 20px 0 0;
        }
      }
    }
  }
  .bottom-box {
    padding: 15px 0;
    background-color: $secondary-color;
    color: $white;
    font-size: 14px;
    > .container-fluid {
      display: flex;
      flex-direction: column;
      align-items: center;

      @include respond-above(md) {
        flex-direction: row;
        justify-content: space-between;
      }

      .copyright {
        padding: 0.5rem 1rem 0.5rem 0;
      }

      .nav {
        text-align: center;
        flex-direction: column;
        @include respond-above(md) {
          text-align: left;
          flex-direction: row;
        }
        .nav-item {
          .nav-link {
            color: $white;

            &:hover {
              color: inherit;
              text-decoration: underline;
            }
          }

          @include respond-above(md) {
            &:last-child {
              .nav-link {
                padding-right: 0;
              }
            }
          }
        }
      }
    }
  }
}
