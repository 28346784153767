.accordion {
  > .card {
    border: 0;
    > .card-header {
      background: none;
      margin-bottom: 0;
      padding: 0.75rem 0;
      display: flex;
      align-items: center;

      > .btn {
        padding: 6px 0;
        width: 100%;
        display: block;
        text-align: left;
        text-decoration: none;
        font-weight: 700;
      }
      > .icon {
        > .icon-close {
          display: none;
        }
      }

      > .btn:hover + .icon {
        color: $secondary-color;
      }

      &.active {
        border-color: $secondary-color;
        > .btn {
          color: $secondary-color;
        }

        > .icon {
          color: $secondary-color;
          .icon-open {
            display: none;
          }
          .icon-close {
            display: block;
          }
        }
      }
    }

    .card-body {
      padding: 1.25rem 0;
    }
  }
}
