.tp-slider {
  $self: &;
  width: 100%;

  .swiper-container {
    @include respond-above(xl) {
      overflow: visible;
    }
  }

  .swiper-slide {
    width: 322px !important;
    max-width: 100%;

    a {
      text-decoration: none;
      display: block;

      .image {
        margin: 0 0 18px;
     padding-top: 136%;
        position: relative;
       
        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .title {
        color: $secondary-color;
      }
      .desc {
        //
      }
      .date {
        font-size: 12px;
        color: $medium-gray-color;
      }

      &:hover {
        .image {
          filter: none;
          opacity: 1;
        }
      }
    }
  }

  .swiper-btns-box {
    //display: none;
    display: flex;
    align-items: flex-start;
    margin: 60px 0 0;

    @include respond-above(md) {
      display: flex;
    }

    .swiper-btn {
      display: flex;
      align-items: center;
      line-height: 0;
      width: 40px;
      height: 24px;
      margin: 0 23px 0 0;
      cursor: pointer;

      &:hover {
        color: $secondary-color;
      }

      &.swiper-button-disabled {
        opacity: 0.6;
        cursor: default;
        color: inherit;
      }
    }
  }

  .swiper-pagination {
    margin: 24px 0 0;
    //display: flex;
    display: none;
    align-items: center;
    justify-content: center;
    width: 100%;

    .swiper-pagination-bullet {
      width: 6px;
      height: 6px;
      margin: 0 8px;
      background: $medium-gray-color;

      &.swiper-pagination-bullet-active {
        width: 10px;
        height: 10px;
        background: $secondary-color;
      }
    }

    @include respond-above(md) {
      display: none;
    }
  }
}
