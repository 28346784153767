.tp-contact {
  $self: &;

  display: flex;
  flex-direction: column;
  width: calc(100% - $base-left-space);

  .tp-select {
    @include respond-above(xl) {
      margin-bottom: 48px;
    }
  }

  .tab-content {
    position: relative;
    order: 3;
    background-color: $secondary-color;
    z-index: 3;
    padding: 30px 15px;
    color: $white;
    margin: 0 -15px;

    @include respond-above(sm) {
      margin: 0;
      min-height: 300px;
      padding: 30px;
      width: 430px;
      margin-top: -270px;
    }

    @include respond-above(xl) {
      padding: 45px;

      margin-left: -$base-center-space;
    }

    .title {
      margin-bottom: 15px;
    }

    .line {
      margin-top: 10px;

      span {
        float: left;
        min-width: 50px;
        padding-right: 10px;
      }

      a {
        color: $white;
      }
    }
  }

  .map-container {
    position: relative;
    z-index: 2;
    order: 2;
    margin-top: 25px;

    @include respond-above(xl) {
      margin-left: -$base-center-space;
    }

    .map-wrapper {
      position: relative;
      width: 100vw;
      left: 50%;
      right: 50%;
      margin: 0 -50vw;
      height: 520px;

      @include respond-above(xl) {
        //left: -#{$base-left-space};
        //margin-left: -40px;
        //padding-right: 64px;
      }

      #map {
        width: 100%;
        height: 100%;
      }
    }
  }
}
