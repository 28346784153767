/*Title animate*/
.unfold-animate {
  transform: translateY(20px);
  transition: transform 1s ease;

  .mask {
    position: relative;
    display: inline-block;
    vertical-align: top;
    padding: 6px 0;
    margin: -6px 0;
    overflow: hidden;
    transform: translateY(calc(100% + 30px));
    transition: transform 0.75s ease;

    span {
      display: block;
      transform: translateY(calc(-100% - 30px));
      transition: transform 0.75s ease;
    }

    $startDelay: 0.05;
    $mult: 0.05;
    @for $i from 1 through 10 {
      &.delay#{$i},
      &.delay#{$i} span {
        transition-delay: #{$startDelay + ($mult * ($i - 1))}s;
      }
    }
  }

  &.delay .mask {
    transition-delay: 0.2s;

    span {
      transition-delay: 0.2s;
    }

    $startDelay2: 0.25;
    $mult2: 0.05;
    @for $i from 1 through 10 {
      &.delay#{$i},
      &.delay#{$i} span {
        transition-delay: #{$startDelay2 + ($mult2 * ($i - 1))}s;
      }
    }
  }
}
.is-inview .unfold-animate,
.page-load #main-screen .unfold-animate,
.page-load #main h1.unfold-animate {
  transform: none;
}

.ie .unfold-animate .mask {
  transform: translateY(100%) translateY(30px);
}
.is-inview .unfold-animate .mask,
.page-load #main-screen .unfold-animate .mask,
.page-load #main h1.unfold-animate .mask {
  transform: none;
}

.ie .unfold-animate .mask span {
  transform: translateY(-100%) translateY(-30px);
}
.is-inview .unfold-animate .mask span,
.page-load #main-screen .unfold-animate .mask span,
.page-load #main h1.unfold-animate .mask span {
  transform: none;
}
