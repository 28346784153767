.cookie-policy-toast {
  position: fixed;
  bottom: 20px;
  right: 20px;
  left: 20px;
  z-index: 1037;

  a {
    text-decoration: underline;
  }
}
