@import "colors";
@import "fonts";

$body-bg: $bg-color;
$body-color: $text-color;

$theme-colors: (
  "primary": $primary-color,
  "secondary": $secondary-color,
  "light": $light-color,
  "danger": $danger-color,
  "success": $success-color,
);

$link-color: $primary-color;
$link-hover-color: $secondary-color;

$font-family-sans-serif: $primary-font, -apple-system, system-ui,
  BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;
$font-family-monospace: Menlo, Monaco, Consolas, "Liberation Mono",
  "Courier New", monospace !default;
$font-family-base: $font-family-sans-serif !default;

$font-size-base: 18;

$image-path: "../media";

$base-left-space: 29.25%;
$base-center-space: 41.5%;

$base-vertical-animation-transform: translateY(30px);
$base-vertical-animation-transition: transform 0.7s ease 0.3s,
  opacity 0.7s ease 0.3s;

$base-unfold-title-max-width: 260px;

$enable-rounded: false;

$enable-responsive-font-sizes: true;

$input-btn-focus-box-shadow: none;
$input-btn-focus-width: 0;

@import "../../node_modules/bootstrap/scss/functions";

$custom-file-text: (
  en: "Browse",
  tr: "Gözat",
);

@import "../../node_modules/bootstrap/scss/bootstrap";
@import "bootstrap-transitions";

@import "media-queries";
@import "general";
@import "header";
@import "footer";
@import "swiper";
@import "select2";
@import "home";
@import "section";
@import "slider";
@import "cc-slider";
@import "under-hero";
@import "title-animation";
@import "inner-page";
@import "contact";
@import "accordion";
@import "flatpickr";
@import "form";
@import "gridAndList";
@import "pagination";
@import "svg-map";
@import "cookie-policy-toast";
