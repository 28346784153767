.tp-header {
  $self: &;

  position: fixed;
  z-index: 1038;
  left: 0;
  top: 0;
  width: 100%;
  background: $white;
  font-weight: 700;

  @include respond-below(md) {
    height: 64px;
    border-bottom: 1px solid $light-gray-color;
  }

  > .container-fluid {
    position: relative;

    //opacity: 0;
    transition: opacity 0.3s linear 0.15s;
  }

  a {
    text-decoration: none;
  }

  .top-box {
    display: none;
    justify-content: space-between;
    align-items: center;
    //padding-top: 16px;
    //padding-bottom: 17px;
    padding-top: 11px;
    padding-bottom: 12px;
    font-size: 16px;

    @include respond-above(md) {
      display: flex;
    }

    .sub-links {
      a {
        margin-right: 30px;
      }
    }

    .right-area {
      display: flex;
      align-items: center;

      .search-wrapper {
        position: relative;
        background: $white;
        transform-origin: 100% 50%;
        width: 32px;
        height: 32px;
        cursor: pointer;
        transition: 250ms ease;
        margin-right: 20px;

        .search-input {
          background: transparent;
          border: 0;
          color: $white;
          outline: none;
          height: 32px;
          width: 0;
          overflow: hidden;
          transform: scale(0);
          transform-origin: 0 50%;
          transition: 275ms ease;
          padding: 0;

          &::placeholder {
            color: darken($secondary-color, 15%);
          }
        }

        .search-button {
          position: absolute;
          top: 50%;
          right: 5px;
          transform: translateY(-50%);
          padding: 0;
          font-size: 24px;
          color: $secondary-color;
          border: 0;

          i {
            pointer-events: none;
          }
        }

        &.focused {
          width: 200px;
          background: $secondary-color;

          .search-input {
            padding: 0 40px 0 12px;
            width: 100%;
            transform: scale(1);
          }

          .search-button {
            color: $white;
          }
        }
      }
    }

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: -1500px;
      right: -1500px;
      height: 1px;
      background: $light-gray-color;
    }
  }

  .bottom-box {
    height: 63px;
    display: flex;
    align-items: center;

    @include respond-between(md, lg) {
      font-size: 17px;
    }

    @include respond-above(md) {
      height: 96px;
      padding-top: 25px;
      padding-bottom: 26px;
    }

    @include respond-above(xl) {
      justify-content: flex-start;
    }
  }

  .logo {
    display: block;
    width: 100px;
    height: 21px;
    order: 2;
    margin-left: 10px;

    @include respond-above(md) {
      width: 160px;
      height: 33px;
      order: 0;
      margin-left: 0;
    }
  }

  .quick-menu-wrapper {
    position: static;
    display: block;
    order: 3;
    margin-left: auto;

    @include respond-above(md) {
      margin-left: initial;
    }

    @include respond-above(xl) {
      display: flex;
      align-items: flex-start;
      margin-left: auto;
    }

    .quick-menu {
      display: flex;
      flex-direction: row;

      position: relative;
      &:before {
        content: "";
        position: absolute;
        height: 41px;
        width: 100%;
        right: 0;
        display: none;
      }

      a {
        line-height: 32px;
        display: flex;
        white-space: nowrap;
        z-index: 1;

        @include respond-above(md) {
          padding-left: 8px;
          justify-content: flex-end;
        }

        .text {
          display: none;
          margin: 0 auto;

          @include respond-above(md) {
            display: block;
            margin-right: 10px;
          }
        }

        i {
          position: relative;
          width: 32px;
          height: 32px;
          text-align: center;
          font-size: 24px;
          line-height: 32px;
          overflow: hidden;
          color: $white;

          &:after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            background-color: $secondary-color;
          }
        }
      }

      .quick-menu-preview {
        position: relative;
        z-index: 2;
        padding-left: 0;

        @include respond-above(md) {
          padding-left: 8px;
        }

        .text {
          display: none;

          @include respond-above(md) {
            display: block;
          }
        }
      }

      .qucik-menu-dropdown {
        display: flex;

        @include respond-above(md) {
          display: block;
          position: absolute;
          right: 0;
          top: 32px;
          transition: all 0.2s ease;
          visibility: hidden;
          opacity: 0;
        }

        a {
          margin-left: 8px;
          background-color: $secondary-color;
          color: $white;

          @include respond-above(md) {
            margin-left: 0;
            margin-top: 8px;
          }

          &:hover {
            background-color: $white;
            color: $secondary-color;
            i {
              @include respond-above(md) {
                color: $secondary-color;
              }
            }
          }
        }
      }

      &:hover {
        &:before {
          display: block;
        }

        .qucik-menu-dropdown {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }

  .js-open-nav {
    position: relative;
    display: inline-block;
    order: 1;
    cursor: pointer;
    height: 32px;
    margin: 0 0 0 -7px;
    width: 32px;
    z-index: 1000;
    background-color: $secondary-color;

    .start-state {
      position: absolute;
      top: 50%;
      left: 50%;
      height: 14px;
      width: 18px;
      margin: -6px 0 0 -9px;

      span {
        position: relative;
        top: 0;
        left: 0;
        display: block;
        width: 18px;
        height: 2px;
        margin: 0 0 3px;
        background: $white;
        transition: all 0.25s ease-in-out, background 0.2s ease;

        &:nth-child(1) {
          transition: all 0.25s ease-in-out 0.375s, background 0.2s ease;
        }
        &:nth-child(2) {
          transition: all 0.25s ease-in-out 0.45s, background 0.2s ease;
        }
        &:nth-child(3) {
          transition: all 0.25s ease-in-out 0.525s, background 0.2s ease;
        }
      }
    }

    .final-state {
      position: absolute;
      top: 9px;
      left: 50%;
      height: 17px;
      width: 18px;
      margin: 0 0 0 -9px;
      transform: rotate(45deg);

      span {
        position: absolute;
        display: block;
        background: $white;
        transition: width 0.25s ease-in-out, height 0.25s ease-in-out;

        &:nth-child(1) {
          left: 8px;
          top: -1px;
          height: 0;
          width: 2px;
          transition-delay: 0s;
        }

        &:nth-child(2) {
          left: 0;
          top: 7px;
          height: 2px;
          width: 0;
          transition-delay: 0.15s;
        }
      }
    }

    @include respond-above(md) {
      display: none;
    }
  }

  .nav-wrapper {
    margin: 0 auto;

    @include respond-above(xl) {
      margin-left: calc(#{$base-left-space} - 160px);
      margin-right: 0;
      width: auto;
    }

    @media screen and (min-width: 1600px) {
      width: $base-center-space;
    }

    @include respond-below(md) {
      position: fixed;
      z-index: 999;
      left: 0;
      top: 64px;
      font-size: 26px;
      line-height: 1.076923076923077em;
      letter-spacing: -0.78px;
      height: calc(100% - 64px);
      width: 100%;
      margin: 0;
      padding: 35px 15% 0;
      overflow: hidden;
      overflow-y: auto;
      background: $white;
      visibility: hidden;
      opacity: 0;
      transform: scale(0.9);
      transition: all 0.3s ease;
    }

    @include respond-below(md) {
      padding: 35px 10% 0;
    }

    @include respond-below(sm) {
      padding: 35px 20px 0;
    }

    > .nav {
      width: 100%;
      display: flex;
      justify-content: space-between;
      @include respond-below(md) {
        flex-wrap: nowrap;
        flex-direction: column;
      }

      > .nav-item {
        display: block;
        padding: 10px 0 10px 0;
        @include respond-above(md) {
          position: static;
          padding: 0px;
        }

        //until better solution
        @include respond-above(md) {
          &:before {
            content: "";
            position: absolute;
            height: 80px;
            width: 420px;
            transform: translateX(-40%);
            display: none;
          }
        }

        > .nav-link {
          display: block;
          position: relative;
          z-index: 2;

          /* &:before {
            content: "";
            position: absolute;
            z-index: -1;
            left: 50%;
            top: 0px;
            height: 80px;
            width: 420px;
            margin: 0 0 0 -210px;
            display: none;
            //background-color: green;
            background: none;
            border-style: solid;
            border-width: 0 210px 80px 210px;
            border-color: transparent transparent #007bff transparent;
          }*/
        }

        @include respond-above(md) {
          /*
          conflict with mobile menu items... controlled by js now
          */
          /*&.desktop:first-child {
            > .nav-link {
              padding-left: 0;
            }
          }

          &.desktop:last-child {
            > .nav-link {
              padding-right: 0;
            }
          }*/
        }

        > .dropdown {
          visibility: hidden;
          opacity: 0;
          transition: all 0.2s ease;
          position: absolute;
          width: 100%;
          left: 0;
          top: 96px;
          border-top: 1px solid $light-gray-color;

          @include respond-below(md) {
            position: relative;
            top: 0;
            display: none;
          }

          @include respond-above(md) {
            &:before {
              content: "";
              position: absolute;
              z-index: -1;
              left: -1500px;
              right: -1500px;
              top: 0;
              bottom: 0;
              background-color: $white;
              box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05);
            }
          }

          .container-fluid {
            display: flex;
            align-items: flex-start;
            padding-top: 40px;
            padding-bottom: 40px;

            .title-wrapper {
              width: $base-left-space;

              @include respond-below(xl) {
                display: none;
              }

              .title {
                font-weight: 700;

                @include respond-above(xl) {
                  max-width: $base-unfold-title-max-width;
                }
              }
            }

            .nav {
              width: calc(100% - #{$base-left-space});
              @include respond-below(xl) {
                width: 100%;
              }

              .nav-link {
                padding-left: 0;
                display: inline;
              }
            }
          }
        }

        //@include respond-above(md) {
        &.has-child:hover {
          &:before {
            display: block;
          }

          > .nav-link {
            color: $secondary-color;
          }

          .dropdown {
            visibility: visible;
            opacity: 1;

            @include respond-below(md) {
              display: block;
            }
          }
        }
        //}
      }
    }
  }
}

.short-header-view .tp-header {
  @include respond-above(md) {
    transform: translateY(-55px);
  }
}

.opened-nav .tp-header {
  .nav-wrapper {
    visibility: visible;
    opacity: 1;
    transform: none;
  }

  .js-open-nav {
    .start-state span {
      width: 0;

      &:nth-child(1) {
        transition-delay: 0s;
      }
      &:nth-child(2) {
        transition-delay: 0.075s;
      }
      &:nth-child(3) {
        transition-delay: 0.15s;
      }
    }

    .final-state {
      span:nth-child(1) {
        height: 18px;
        transition-delay: 0.425s;
      }

      span:nth-child(2) {
        transition-delay: 0.275s;
        width: 18px;
      }
    }
  }
}

//mobile search
.mobile-search-wrapper {
  position: relative;
  background: $secondary-color;
  width: 100%;
  height: 42px;

  .mobile-search-input {
    background: transparent;
    border: 0;
    color: $white;
    outline: none;
    height: 42px;
    width: 100%;
    overflow: hidden;
    padding: 0 40px 0 12px;

    &::placeholder {
      color: darken($secondary-color, 15%);
    }
  }

  .mobile-search-button {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    padding: 0;
    font-size: 32px;
    color: $white;
    border: 0;

    i {
      pointer-events: none;
    }
  }
}

//header
.page-visible .tp-header {
  transition: transform 0.6s ease;
}

.ie .location-animate .tp-header {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.location-animate.page-visible .tp-header {
  opacity: 1;
}

.page-load .tp-header > .container,
html:not(.index-page) .location-animate.page-visible .tp-header .container {
  opacity: 1;
}

//logo
.no-touchevents .tp-header .logo:hover {
  opacity: 0.7;
}

//link
.no-touchevents .tp-header .sub-links a:hover.active {
  opacity: 0.7;
}
