.tp-inner-page {
  $self: &;
  position: relative;
  overflow: hidden;
  padding: 30px 0 60px;

  @include respond-above(md) {
    padding: 60px 0 60px;
  }

  > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    > .title-wrapper {
      position: relative;
      z-index: 3;
      width: 100%;
      padding-right: 0;

      @include respond-above(xl) {
        width: $base-left-space;
        //padding-right: 64px;
      }

      > .parent-title {
        font-weight: 700;
        margin-bottom: 0.2rem;
        color: $medium-gray-color;

        @include respond-above(xl) {
          max-width: $base-unfold-title-max-width;
          margin-bottom: 0.3rem;
        }
      }

      > .title {
        font-weight: 700;
        margin-bottom: 2rem;

        @include respond-above(xl) {
          max-width: $base-unfold-title-max-width;
          margin-bottom: 3rem;
        }
      }

      > .list-group {
        opacity: 0;
        transform: $base-vertical-animation-transform;
        transition: $base-vertical-animation-transition;

        margin-bottom: 2rem;

        @include respond-above(xl) {
          max-width: 80%;
          margin-bottom: 3rem;
        }
      }

      .list-group-item {
        &.active {
          background-color: $secondary-color;
          border-color: $secondary-color;
        }
      }
    }

    > .content-wrapper {
      position: relative;
      width: 100%;

      opacity: 0;
      transform: $base-vertical-animation-transform;
      transition: $base-vertical-animation-transition;

      @include respond-above(xl) {
        width: calc(100% - #{$base-left-space});
      }
      &:before {
        content: "";
        position: absolute;
        z-index: 2;
        right: 100%;
        top: 0;
        bottom: 0;
        width: 2500px;
        background-color: $white;
        display: none;
      }

      > h1 {
        margin-bottom: 32px;
      }

      > .date {
        font-size: 12px;
        color: $medium-gray-color;
        display: block;
        margin-bottom: 40px;
        @include respond-above(xl) {
          margin-bottom: 54px;
        }
      }

      > .large-indent {
        margin-bottom: 40px;
        @include respond-above(xl) {
          margin-bottom: 88px;
        }
      }

      > .date + .large-indent,
      > h1 + .large-indent {
        margin-top: 40px;
        @include respond-above(xl) {
          margin-top: 88px;
        }
      }

      > .full-width {
        @include respond-above(xl) {
          width: calc(100% + 100px);
          max-width: calc(100% + 100px);
        }
      }

      > .image.fixed-height img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      > .wysiwyg-area {
        max-width: 840px;

        p {
          margin: 22px 0;
        }

        a:not(.btn) {
          color: $secondary-color;
        }
      }

      > .form-wrapper {
        max-width: 840px;
      }

      > .btn {
        min-width: 264px;
        margin: 40px 0;
      }
    }

    /*.tp-select {
      width: 300px;
      max-width: 100%;

      &:first-child {
        margin-right: 10px;
      }
    }*/
  }

  &.bg-light {
    .content-wrapper {
      &:before {
        background-color: $light-color;
      }
    }
  }

  &.bg-secondary {
    .content-wrapper {
      color: $white;
      &:before {
        background-color: $secondary-color;
      }
    }

    .title-wrapper {
      > .title {
        color: $white;
      }
    }
  }

  &.is-inview {
    .content-wrapper {
      transform: none;
      opacity: 1;
    }

    .title-wrapper {
      .list-group {
        transform: none;
        opacity: 1;
      }
    }
  }
}
