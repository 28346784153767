.index-slider {
  $self: &;
  position: relative;

  &-duration {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 99;

    #{$self}-duration-bar {
      height: 100%;
      width: 0;
      background-color: $secondary-color;
    }
  }

  &__content {
    position: absolute;
    left: 10%;
    bottom: 10%;
    max-width: 80%;
    background-color: $secondary-color;
    color: $white;
    padding: 1rem;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
      margin-bottom: 0;
    }
  }

  &__custom {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    padding-top: 100%;
    overflow: hidden;

    @include respond-between(xs, md) {
      padding-top: 50%;
    }

    @include respond-above(md) {
      padding-top: 30%;
    }
  }

  &__video {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    overflow: hidden;

    &.aspect-21by9 {
      padding-top: 42.85%;
    }

    &.aspect-16by9 {
      padding-top: 56.25%;
    }

    &.aspect-4by3 {
      padding-top: 75%;
    }

    &.aspect-1by1 {
      padding-top: 100%;
    }

    @include respond-above(md) {
      padding-top: 30%;
    }

    video {
      top: 50%;
      left: 0;
      position: absolute;
      width: 100%;
      height: auto;
      transform: translateY(-50%);
    }
  }

  &__image,
  &__image--tablet,
  &__image--desktop {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    display: none;
  }

  &__image {
    padding-top: 100%;
  }

  &__image--tablet {
    padding-top: 50%;
  }

  &__image--desktop {
    padding-top: 30%;
  }

  @include respond-below(xs) {
    &__image {
      display: block;
    }
  }

  @include respond-between(xs, md) {
    &__image--tablet {
      display: block;
    }
  }

  @include respond-above(md) {
    &__image--desktop {
      display: block;
    }
  }
}
