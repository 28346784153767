.tp-grid-and-list {
  position: relative;

  .item-bg {
    width: 100px;
    height: 100px;
    position: absolute;
    background: $secondary-color;
    box-shadow: 0px 14px 20px rgba(0, 0, 0, 0.2);
    opacity: 0;
    transition: all 0.2s;

    &.active {
      left: 0;
      top: 0;
      opacity: 1;
    }
  }

  .item {
    display: flex;
    margin-bottom: 30px;

    .content-wrapper {
      text-decoration: none;
      transition: color 0.2s ease;
      display: block;
      width: 100%;

      .image {
        position: relative;
        width: 100%;
        padding-top: 62%;
        margin: 0 0 15px;
        box-shadow: 2px 7px 15px 1px rgba(0, 0, 0, 0.2);
        border-radius: 15px;
        overflow: hidden;
        transition: box-shadow 0.2s ease;

        img {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          object-fit: cover;
          display: block;
        }
      }

      .content {
        padding: 15px;
        &.scale {
          padding: 0;
          transition: transform 0.2s ease;
        }

        .text-secondary {
          transition: color 0.2s ease;
        }

        .date,
        .info {
          font-size: 12px;
          color: $medium-gray-color;

          transition: color 0.2s ease;

          &.large {
            font-size: 14px;
          }
        }
      }
    }

    &.active {
      .content-wrapper {
        color: $white;

        .text-secondary {
          color: $white !important;
        }

        .image {
          box-shadow: none;
        }

        .content {
          &.scale {
            transform: scale(0.9);
          }
          .date,
          .info {
            color: $white;
          }
          .btn {
            background-color: $white;
            border-color: $white;
            color: $secondary-color;
          }
        }
      }
    }
  }

  &.grid {
    .item {
      margin-bottom: 45px;
    }
  }
}
