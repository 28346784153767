html {
  height: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
}

body {
  overflow: auto;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
  border: 0 none;
}

form {
  margin: 0;
}

::-moz-selection {
  background: $primary-color;
  color: $white;
}

:focus {
  outline: none;
}

::selection {
  background: $primary-color;
  color: $white;
}

.is-hidden {
  display: none !important;
}

/*.data-scroll-box {
  overflow-x: hidden;
}

.opened-nav .data-scroll-box,
.opened-nav body {
  overflow: hidden;
}

[data-scroll] {
  padding-top: 63px;
  @include respond-above(md) {
    padding-top: 151px;
  }
}*/

[data-scroll-container] {
  position: relative;
  padding-top: 63px;
  @include respond-above(md) {
    padding-top: 151px;
  }
}

html.has-scroll-smooth .c-scrollbar_thumb {
  background-color: $secondary-color;
  opacity: 1;
  width: 4px;
}

.c-scrollbar {
  z-index: 1039;
}

.container-fluid {
  @include respond-above(lg) {
    padding-left: 40px;
    padding-right: 40px;
  }
  @include respond-above(xl) {
    padding-left: 100px;
    padding-right: 100px;
  }
}

thead.sticky {
  > tr,
  > tr > td {
    background-color: $secondary-color;
    color: $white;
  }
}

/*radius card*/

.radius-card {
  background-color: transparent;
  width: 100%;
  height: 160px;

  @include respond-above(sm) {
    height: 220px;
  }

  > .radius-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;

    > a {
      position: absolute;
      width: 100%;
      height: 100%;
      box-shadow: 0 0.25vmin 0.25vmin rgba(black, 0.25);

      background: linear-gradient(135deg, $secondary-color, #be0510);
      display: flex;
      align-items: center;
      padding: 20px;
      text-decoration: none;

      @include respond-above(sm) {
        padding: 40px;
        border-radius: 1000px 50px 50px 1000px;
      }

      > .icon-wrapper {
        margin: 20px;
        padding: 15px;
        border-radius: 1000px;
        border: 10px solid $white;
        background: $secondary-color;
        box-shadow: 0px 5px 50px 0px rgba(0, 0, 0, 0.2),
          0px 0px 0px 7px rgba(0, 0, 0, 0.1);

        i {
          color: $white;
          font-size: 60px;
        }

        .svg-icon {
          width: 60px;
          height: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
          filter: grayscale(1) brightness(150%);

          svg {
            width: 50px;
            height: 50px;
            margin-bottom: 5px;
          }
        }
      }

      > .text {
        font-size: 30px;
        font-weight: bold;
        color: $white;
      }

      &:hover {
        background: linear-gradient(135deg, #be0510, $secondary-color);

        > .icon-wrapper {
          border-color: $secondary-color;
          background: $white;
          box-shadow: 0px 5px 50px 0px rgba(0, 0, 0, 0.1),
            0px 0px 0px 7px rgba(0, 0, 0, 0.05);

          i {
            color: $secondary-color;
          }

          .svg-icon {
            filter: none;
          }
        }
      }
    }
  }

  &.reverse {
    @include respond-above(sm) {
      > .radius-card-inner {
        > a {
          justify-content: space-between;
          border-radius: 50px 1000px 1000px 50px;

          > .icon-wrapper {
            order: 2;
          }
        }
      }
    }
  }
}
