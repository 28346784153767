.form {
  position: relative;

  .form-control {
    border-top: 0;
    border-left: 0;
    border-right: 0;

    + .input-group-append {
      .btn {
        border-bottom: 1px solid #ced4da;
      }
    }

    &.is-valid + .input-group-append {
      .btn {
        border-bottom-color: $success-color;
      }
    }

    &.is-invalid + .input-group-append {
      .btn {
        border-bottom-color: $danger-color;
      }
    }
  }

  .custom-file {
    &-label {
      border-top: 0;
      border-left: 0;
      border-right: 0;
      color: $medium-gray-color;

      &.selected {
        color: $primary-color;
      }

      &:after {
        color: $white;
        background-color: $secondary-color;
        height: inherit;
        bottom: 0px;
      }
    }
  }

  .form-group {
    margin-bottom: 2rem;

    > label {
      color: $secondary-color;
      display: block;

      > small {
        font-size: 60%;
      }
    }
  }
}

.form-wrapper {
  position: relative;
  .form__loader {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 1;
    color: $secondary-color;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .form-result {
    position: relative;
    z-index: 2;
  }
}

.was-validated .form-control:invalid + .input-group-append {
  .btn {
    border-bottom-color: $danger-color;
  }
}

.was-validated .form-control:valid + .input-group-append {
  .btn {
    border-bottom-color: $success-color;
  }
}
