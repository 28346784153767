$primary-color: #4d515c;
$secondary-color: #e30613;
$danger-color: #a5040e;
$success-color: #28a745;
$light-color: #f5f5f5;

$light-gray-color: #e3e4e8;
$medium-gray-color: #b5b6be;

$bg-color: #ffffff;
$text-color: #4d515c;
