$flat-theme-light-color: $secondary-color;
$flat-theme-dark-color: rgba(0, 0, 0, 0.25);
$flat-theme-gray-color: $secondary-color;
$flat-theme-white-color: $white;
$flat-theme-selection-height: 41;

.tp-select {
  position: relative;
  .select2 {
    width: 100% !important;
  }

  &.max-width {
    width: 300px;
  }

  &__loader {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 1;
    color: $flat-theme-white-color;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.was-validated .custom-select:invalid + .select2 .select2-selection {
  border: 1px solid $danger !important;
}
.was-validated .custom-select:valid + .select2 .select2-selection {
  border: 1px solid $success !important;
}
*:focus {
  outline: 0px;
}

.select2-dropdown {
  border: none;
  border-radius: 0;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.select2-results > .select2-results__options {
  max-height: 13em;
  overflow-y: auto;
}

.select2-container--open .select2-dropdown--below,
.select2-container--open .select2-dropdown--above {
  background: $flat-theme-light-color;
}
.select2-container--flat {
  .select2-container--focus .select2-selection--multiple {
    border: 1px solid $flat-theme-dark-color;
  }
  .select2-results__option {
    color: $flat-theme-white-color;
  }
  .select2-results__option--highlighted[aria-selected] {
    background: $flat-theme-dark-color !important;
    color: $flat-theme-white-color;
  }
  .select2-results__option[aria-selected="true"] {
    background: $flat-theme-dark-color;
    color: $flat-theme-white-color;
    //opacity: 0.8;
  }
  .select2-selection--single {
    border-radius: 0px;
  }
  &.select2-container--open {
    .select2-selection__arrow {
      b {
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
      }
    }
  }
  span.select2-search {
    input {
      height: 44px !important;
    }
  }
}

.select2-container {
  .select2-choice {
    border: 2px solid $flat-theme-gray-color;
    height: 36px;
    border-radius: 0px;
    font-size: 14px;
    text-indent: 1px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background-image: none;
    div {
      border-left: 2px solid $flat-theme-gray-color;
      -webkit-border-radius: 0 4px 4px 0;
      -moz-border-radius: 0 4px 4px 0;
      border-radius: 0 4px 4px 0;

      -webkit-background-clip: padding-box;
      -moz-background-clip: padding;
      background-clip: padding-box;
    }
    .select2-arrow {
      border: 0px;
      border-radius: 0px;
      background: transparent;
      background-image: none;
    }
  }
  *:focus {
    outline: 0px;
  }
  &.select2-drop-above .select2-choice {
    border-bottom-color: $flat-theme-gray-color;
    border-radius: 0px;
  }
}

.select2-drop {
  margin-top: -2px;
  border: 2px solid $flat-theme-gray-color;
  border-top: 0;
  border-radius: 0px !important;
  -webkit-border-radius: 0 0 6px 6px;
  -moz-border-radius: 0 0 6px 6px;
  border-radius: 0 0 6px 6px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  &.select2-drop-above {
    margin-top: 2px;
    border-top: 2px solid $flat-theme-gray-color;
    border-bottom: 0;
    -webkit-border-radius: 6px 6px 0 0;
    -moz-border-radius: 6px 6px 0 0;
    border-radius: 6px 6px 0 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
}

.select2-search {
  margin-top: 3px;
  input {
    height: 26px;
    border: 2px solid $flat-theme-gray-color;
  }
}

.select2-container-active .select2-choice,
.select2-container-active .select2-choices {
  border: 2px solid $flat-theme-gray-color;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.select2-dropdown-open .select2-choice {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-bottomleft: 0;
  border-bottom-left-radius: 0;

  -webkit-border-bottom-right-radius: 0;
  -moz-border-radius-bottomright: 0;
  border-bottom-right-radius: 0;
  .select2-choice div {
    background: transparent;
    border-left: none;
    filter: none;
  }
}

.select2-results {
  padding: 0 0 0 0px;
  margin: 4px 0px 0px 0;
  .select2-highlighted {
    background: $flat-theme-dark-color;
    color: $flat-theme-white-color;
    border-radius: 0px;
  }
}

.select2-container-multi {
  .select2-choices {
    height: auto !important;
    height: 1%;

    border: 2px solid $flat-theme-gray-color;
  }
  &.select2-container-active .select2-choices {
    border: 2px solid $flat-theme-gray-color;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
}

/****** Single SCSS *******/
.select2-container--flat {
  .select2-selection--single {
    background: $flat-theme-light-color;
    border: 0px;
    height: #{$flat-theme-selection-height}px;
    .select2-selection__rendered {
      color: $flat-theme-white-color;
      line-height: #{$flat-theme-selection-height}px;
    }
    .select2-selection__arrow {
      height: #{$flat-theme-selection-height - 7}px;
      position: absolute;
      top: 1px;
      right: 1px;
      width: 20px;
      b {
        border-color: $flat-theme-white-color transparent transparent
          transparent;
        top: 60%;
        border-style: solid;
        border-width: 5px 4px 0 4px;
        height: 0;
        left: 50%;
        margin-left: -4px;
        margin-top: -2px;
        position: absolute;
        width: 0;
      }
    }
    .select2-selection__placeholder {
      color: $flat-theme-white-color;
    }
    .select2-selection__arrow b {
    }
    .select2-selection__clear {
      cursor: pointer;
      float: right;
    }
  }
}

/****** Multiple SCSS *******/
.select2-container--flat {
  .select2-selection--multiple {
    border: 1px solid $flat-theme-dark-color;
    .select2-selection__choice__remove:hover {
      color: $flat-theme-dark-color;
      cursor: pointer;
    }
    .select2-selection__rendered {
      box-sizing: border-box;
      list-style: none;
      margin: 0;
      padding: 0 5px;
      width: 100%;
    }
    .select2-selection__choice {
      background-color: $flat-theme-light-color;
      color: $flat-theme-white-color;
      border: 1px solid $flat-theme-light-color;
      border-radius: 0px;
      padding: 3px 5px;
      cursor: default;
      float: left;
      margin-right: 5px;
      margin-top: 5px;
    }
    .select2-selection__choice__remove {
      color: $flat-theme-dark-color;
      margin-right: 6px;
      margin-left: 6px;
      float: right;
    }
  }
}
