.tp-section {
  $self: &;
  position: relative;
  overflow: hidden;
  padding: 30px 0 60px;

  @include respond-above(md) {
    padding: 60px 0 60px;
  }

  > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    > .title-wrapper {
      position: relative;
      z-index: 3;
      width: 100%;
      padding-right: 0;
      margin-bottom: 3rem;
      @include respond-above(xl) {
        margin-bottom: 0;
      }

      @include respond-above(xl) {
        width: $base-left-space;
        //padding-right: 64px;
      }

      > .title {
        font-weight: 700;
        //margin-bottom: 3rem;

        @include respond-above(xl) {
          max-width: $base-unfold-title-max-width;
        }
      }

      > a {
        position: relative;
        display: inline-block;
        opacity: 0;
        transform: $base-vertical-animation-transform;
        transition: $base-vertical-animation-transition;
      }
    }

    > .content-wrapper {
      position: relative;
      width: 100%;

      opacity: 0;
      transform: $base-vertical-animation-transform;
      transition: $base-vertical-animation-transition;

      @include respond-above(xl) {
        width: calc(100% - #{$base-left-space});
      }
      &:before {
        content: "";
        position: absolute;
        z-index: 2;
        right: 100%;
        top: 0;
        bottom: 0;
        width: 2500px;
        background-color: $white;
      }
    }
  }

  &.bg-light {
    .content-wrapper {
      &:before {
        background-color: $light-color;
      }
    }
  }

  $l-color: lighten($light-color, 3%);
  &.bg-light-gray {
    background-color: $l-color;
    .content-wrapper {
      &:before {
        background-color: $l-color;
      }
    }
  }

  &.bg-primary {
    .title-wrapper .title {
      color: $white;
    }
    .content-wrapper {
      color: $white;
      a {
        color: $white;
      }
      &:before {
        background-color: $primary-color;
      }
    }
  }

  &.bg-secondary {
    .content-wrapper {
      color: $white;
      &:before {
        background-color: $secondary-color;
      }
    }

    .title-wrapper {
      > .title {
        color: $white;
      }

      > a {
        color: $white;
      }
    }
  }

  &.is-inview {
    .content-wrapper,
    .title-wrapper > a {
      transform: none;
      opacity: 1;
    }
  }
}
