.svg-map {
  $self: &;
  position: relative;

  &__tooltip {
    position: absolute;
    z-index: 2;

    div {
      display: inline-block;
      background: $secondary-color;
      color: $white;
      padding: 5px 10px;
    }
  }

  &__wrapper {
    #{$self}__svg {
      width: 100%;
      height: auto;

      path {
        cursor: pointer;
        fill: $light-gray-color;

        &.hover {
          fill: $medium-gray-color;
        }
        &.active {
          fill: $primary-color;
          cursor: default;
        }
      }
    }
  }
}
